.menu-container {
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 72px;
  padding: 0 100px;
  .logo img {
    max-width: 180px;
  }
}

.menu-container .menu {
  display: inline-flex;
  justify-content: left;
  align-items: center;
}

.menu-container .logo {
  align-items: center;
  display: flex;
  gap: 13px;
  padding: 0 24px;
}

.sign-in-box {
  display: inline-flex;
  justify-content: right;
  align-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 50px;
  .btn-common {
    border-radius: 56px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;

    img {
      width: 20px;
      height: 20px;
    }
  }
  .btn-credits {
    border: 1px solid rgba(15, 235, 166, 0.50);
    background: linear-gradient(270deg, rgba(15, 235, 166, 0.00) 0%, rgba(15, 235, 166, 0.20) 100%);
    backdrop-filter: blur(6px);
    color: #fff;
    padding: 12px 24px 12px 16px;
  }

  .btn-premium {
    border: 2px solid rgba(255, 255, 255, 0.50);
    background: #0FEBA6;
    box-shadow: 0 3px 0 0 #077351;
    color: #1A1A1A;
    padding: 12px 32px 12px 24px;
    img {
      width: 16px;
      height: 16px;
    }
  }

  .btn-profile {
    border-radius: 999px;
    border: 1px solid var(--Stroke-Card, #0FEBA6);
    background: var(--BG---Green-2, linear-gradient(270deg, rgba(15, 235, 166, 0.00) 0%, rgba(15, 235, 166, 0.20) 100%));
    width: 48px;
    height: 48px;
  }

}

.sign-in-box .sign-in-btn {
  border-radius: 28px;
  backdrop-filter: blur(2px);
  position: relative;
  color: #1A1A1A;
  background: #0FEBA6;
}

.sign-in-box .create-btn {
  background: var(--sign-in-1);
}

.menu-link {
  &:hover {
    text-decoration: underline;
  }
}