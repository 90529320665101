.app-container {
  background-color: #f8f9fa;
  min-height: 100vh;
}

/* Header */
.app-header {
  display: flex;
  margin-bottom: 20px;
  background: white;
  padding: 20px 0;
}

.app-content {
  padding: 24px;
}

.icon-back {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 0 12px;
  text-decoration: none;
  color: black;

  :hover {
    cursor: pointer;
  }
}

.btn-credit {
  border-radius: 32px;
  padding: 8px;
  background: linear-gradient(90deg, rgba(41, 144, 255, 0.10) -59.62%, rgba(134, 41, 255, 0.10) 110.35%);

  span {
    padding: 4px;
  }
}

.btn-create {
  padding: 14px;
  background: var(--sign-in-1);
}

.ic-credit {
  max-width: 24px;
  max-height: 24px;
}

/* Sidebar */
.sidebar {
  width: 300px;
}


.btn-generate {
  background: none;
  box-shadow: none;
  padding: 16px 0;
  button {
    width: 100%;
    display: flex;
    border-radius: 36px;
    justify-content: center;
    &:disabled {
      padding: 8px 0;
    }
  }

}

.selected-style-box {
  border-radius: 8px;
  padding: 8px;
  border: solid 1px #d0d2d6;
  .style-img {
    border-radius: 4px;
    max-width: 60px;
  }
  .style-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .style-name {
    text-overflow: ellipsis;
  }
  .badge {
    max-width: 50px;
    color: #ff9a27;
    background-color: #ffefdc !important;
  }
}

.sidebar .form-label {
  font-weight: bold;
}

.sidebar textarea {
  resize: none;
}

.sidebar .upload-container {
  border: 1px solid #ced4da;
  border-radius: 8px;
}

.sidebar .model-container {
  border: 1px solid #ced4da;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.sidebar .model-container img {
  border-radius: 50%;
  margin-right: 10px;
}

/* Main Content */
.main-content {
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  .logo-icon {
    max-width: 32px;
    max-height: 32px;
  }
  .title {
    font-size: 14px;
  }
  .content-result {

    .img-item {
      max-width: 230px;
      border-radius: 8px;
      width: 100%;
    }
  }
  .no-result {
    justify-content: center;
    flex: auto;
    display: flex;
    align-content: center;
    align-items: center;
    .no-result-img {
      max-width: 150px;
    }
  }
}


/* Responsive */
@media (max-width: 768px) {
  .app-container {
    padding: 10px;
  }

  .sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  .main-content {
    height: auto;
  }
}

.upload-label {
  display: block;
  position: relative;
  border: 1px dashed #d3d3d3; // Viền nét đứt
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: 0.3s ease;
}

.upload-label:hover {
  background-color: #f0f0f0;
}

.upload-input {
  display: none;
}

.upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.upload-icon {
  width: 40px;
  height: 40px;
  background: url("../../assets/images/ic_image.png") no-repeat center center;
  background-size: cover;
}

.upload-text {
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

.upload-preview {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.remove-image-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}

.image-preview-container:hover .remove-image-btn {
  display: flex;
}

.model-img {
  max-width: 50px;
}

.model-container span {
  display: flex;
  padding-left: 8px;
  font-size: 16px;
}