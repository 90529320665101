.menu-nav {
    padding: 10px 20px;
    color: var(--text-primary);

    .menu-list {
        display: flex; /* Make the list horizontal */
        list-style: none;
        padding: 0;
        margin: 0;

        .menu-item {
            position: relative; /* For dropdown positioning */
            margin: 0 15px; /* Space between items */

            .menu-link {
                font-size: 16px;
                font-weight: 500;
                color: #fff;
                text-decoration: none;
                transition: color 0.3s;
                &:hover {
                    text-decoration: underline;
                }
            }

            &.dropdown {
                .menu-link::after {
                    content: "▼";
                    font-size: 10px;
                    margin-left: 5px;
                }

                .dropdown-menu {
                    display: none;
                    position: absolute;
                    top: 100%; /* Position the dropdown below the menu */
                    left: 0;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    padding: 10px;
                    list-style: none;
                    z-index: 10;

                    li {
                        margin: 5px 0;

                        a {
                            color: #fff;
                            font-size: 14px;
                            text-decoration: none;

                            &:hover {
                                background: var(--sign-in-1);
                                border-radius: 5px;
                                padding: 2px 5px;
                            }
                        }
                    }
                }

                &:hover .dropdown-menu {
                    display: block; /* Show the dropdown menu on hover */
                }
            }
        }
    }
}
