.login-popup .modal-content {
  border-radius: 10px;
  background: #111;
  color: white;
}

.login-popup .btn-dark {
  background: #222;
  border: none;
  padding: 10px;
  display: flex;
  justify-content: start;
  span {
    justify-content: center;
  }
  .social-icon {
    max-width: 24px;
  }
}

.login-popup .btn-dark:hover {
  background: #333;
}

.login-popup .btn-success {
  background: #00ff99;
  border: none;
  font-weight: bold;
}

.login-popup a {
  color: #00ff99;
  text-decoration: none;
}

.login-popup a:hover {
  text-decoration: underline;
}

.term-text {
  opacity: 0.5;
  a {
    color: #fff;
  }
}

.login-popup {
  h3 {
    background: linear-gradient(90deg, #FFF 21.77%, #999 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .title {
    font-size: 32px;
  }
  .btn-dark {
    border-radius: 56px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: #1A1A1A;
    backdrop-filter: blur(6px);
  }
  .bi {
    align-items: start;
  }
  .hr-or {
    opacity: 0.5;
  }
  .btn-close-white {
    position: absolute;
    top: 0;
    right: -5%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.20);
    padding: 0;
    cursor: pointer;
  }
  .btn-success {
    color: #1A1A1A;
    border-radius: 56px;
    border: 2px solid rgba(255, 255, 255, 0.50);
    background: #0FEBA6;
    box-shadow: 0px 3px 0px 0px #077351;
  }
  label {
    color: var(--Gray-10, #F4F4F4);
  }
  .email-input {
    border-radius: 40px;
    background: #1A1A1A;
    padding: 12px 16px;
    border: none;
    &::placeholder {
      color: var(--Gray-10, #F4F4F4);
      opacity: 0.5;
    }
  }
}
