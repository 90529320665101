.image-preview-modal {
  .modal-content {
    background: linear-gradient(180deg, #000 0%, #1A1A1A 100%);
    border: 1px solid var(--Stroke-Card, #0FEBA6);
    border-radius: 16px;
  }

  .modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-title {
      color: #fff;
      font-size: 1.2rem;
    }

    .btn-close {
      filter: invert(1) brightness(200%);
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }

    .modal-header-buttons {
      display: flex;
      gap: 8px;
      
      button {
        padding: 4px 12px;
      }
    }
  }

  .modal-body {
    padding: 20px;

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
      max-height: 70vh;
      overflow: hidden;

      .preview-image {
        max-width: 100%;
        max-height: 70vh;
        object-fit: contain;
        border-radius: 8px;
        transition: transform 0.3s ease;
      }
    }
  }

  .modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px;

    .btn-secondary {
      background: rgba(255, 255, 255, 0.1);
      border: none;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .btn-success {
      background: #0FEBA6;
      border: none;
      color: #1A1A1A;
      &:hover {
        background: #0cd696;
      }
    }
  }
} 