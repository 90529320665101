@import './variables';
@import './mixins';

/* Import fonts */
@font-face {
  font-family: 'Aeonik';
  src: url('../assets/fonts/Aeonik-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

/* Apply global styles */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Aeonik', sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
}


.btn-common {
  border-radius: 28px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(2px);
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 10px 20px;
}

.btn-select {
  background: var(--sign-in-1);
}

.btn-select-secondary {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  background: #FFF;
  color: #8C8C8C;
  :hover {
    background: var(--sign-in-1);
    backdrop-filter: blur(2px);
  }
}