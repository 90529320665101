.toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;

  .custom-toast {
    background: #2a2a2a;
    color: white;
    border: none;
    border-radius: 8px;
    min-width: 250px;
    
    &.success {
      border-left: 4px solid #0feb96;
    }
    
    &.error {
      border-left: 4px solid #dc3545;
    }
    
    &.warning {
      border-left: 4px solid #ffc107;
    }
    
    &.info {
      border-left: 4px solid #0dcaf0;
    }

    .toast-body {
      padding: 12px 16px;
      font-size: 0.875rem;
    }
  }
} 