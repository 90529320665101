.text-hero-section {
  max-width: 60%;
}

.text-hero {
  padding: 0 30%;
  top: 32px;
  h1 {
    margin-top: 16px;
    background: linear-gradient(90deg, #FFF 21.77%, #999 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .dl-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;

    img {
      cursor: pointer;
      max-width: 150px;
    }
  }
}

.text-hero-description {
  color: #FFF;
  text-align: center;
  padding: 8px 12px;
  opacity: 0.7;
  border-radius: 32px;
  border: 1px solid rgba(15, 235, 166, 0.5);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
}

.btn-download {
  background: var(--sign-in-1);
  width: 200px;
  height: 60px;
  font-size: 16px;
  line-height: 20px;
}

img {
  width: 100%;
}

.main-container {
  background: black url('../../assets/images/bg_hero_1.png') no-repeat top;
  background-size: contain;
  width: 100%;
}

.intro-1 {
  margin-top: 200px;

  .generate-intro-box {
    display: flex;
    margin-top: 48px;

    input {
      border-radius: 48px;
      border: 4px solid transparent;
      padding: 4px;
      width: 100%;
      color: #fff;
    }
    .generate-input {
      border-radius: 48px;
      border: 4px solid transparent;
      padding: 4px;
      width: 100%;
      color: #fff;
    }

    .btn {
      border-radius: 999px;
      border: 2px solid rgba(255, 255, 255, 0.5);
      background: linear-gradient(180deg, #0FEBA6 0%, #0CB681 100%);
    }
  }

  .image-hero-section-2 {
    margin-top: 135px;
  }
}

.generate-wrapper {
  display: flex;
  align-items: center;
  border-radius: 48px;
  border: 4px solid transparent;
  background: rgba(255, 255, 255, 0.16);
  padding: 6px;
  width: 100%;

  input {
    flex: 1;
    border: none;
    background: transparent;
    padding: 6px 12px;
    color: #fff;
    outline: none;
  }

  button {
    border: none;
    background: #17a2b8;
    color: white;
    padding: 8px 16px;
    border-radius: 48px;
    cursor: pointer;
  }
}
.intro-2 {
  .text-hero-2 {
    margin-top: 110px;
    padding: 0 25%;
    img {
      max-width: 300px;
    }
    h1 {
      margin-top: -10%;
      background: linear-gradient(90deg, #FFF 21.77%, #999 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.intro-3 {
  background: url("../../assets/images/bg_dot_3.png") no-repeat center center;
  background-size: cover;
  width: 100%;
  margin-top: 250px;
  padding-bottom: 5%;

  .bg-intro-3 {
    background: var(--sign-in-1);
    border-radius: 16px;
  }

  h1,
  p {
    text-align: start;
    color: #FFF;
  }

  p {
    line-height: 1.2;
  }

  h1 {
    line-height: 40px;
  }

  .download-app-box {
    padding: 200px;

    .dl-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 150px;

      img {
        cursor: pointer;
      }
    }
  }

  .iphone-img {
    max-width: 450px;
    margin-left: -10%;
    margin-top: -15%;
    z-index: 99;
  }

  .text-hero-3 {
    margin-top: -10%;
    margin-left: -1%;
    width: 100%;
    z-index: 9;
  }
}

.text-hero-2-section {
  margin-top: 100px;
  max-width: 50%;
}
