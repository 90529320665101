$primary-font: "Space Grotesk", Helvetica, Arial, sans-serif;
$secondary-font: "Roboto", sans-serif;

// Using variables in elements
body {
  font-family: $primary-font;
}

h2 {
  font-family: $secondary-font;
}

:root {
  --primary-color: #2990FF;
  --secondary-color: #8629FF;
  --sign-in-1: linear-gradient(90deg, #2990FF -59.62%, #8629FF 110.35%);
  --text-primary: #1A1A1A;
  --primitives-base-white: #fff;
}