.history-option {
  margin-top: 1rem;

  .history-title {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    text-align: start;
    margin-bottom: 1rem;
  }

  .history-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    max-height: 300px;
    overflow-y: auto;
    padding-right: 4px;

    /* Scrollbar Styles */
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #1a1a1a;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #444;
      border-radius: 3px;

      &:hover {
        background: #555;
      }
    }
  }

  .history-item {
    background: #2a2a2a;
    border-radius: 8px;
    padding: 0.75rem;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;

    &:hover {
      background: #3a3a3a;
    }
  }

  .history-prompt {
    color: #fff;
    font-size: 0.875rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
    min-height: calc(1.4em * 2);
    padding-right: 32px; /* Increased space for copy button */
  }

  .copy-button {
    position: absolute;
    right: 8px;
    bottom: 16px;
    width: 24px;
    height: 24px;
    padding: 4px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease;
    cursor: pointer;

    &:hover:not(:disabled) {
      background: rgba(255, 255, 255, 0.15);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  }

  .empty-message {
    text-align: center;
    color: #888;
    padding: 1rem;
    background: #2a2a2a;
    border-radius: 8px;
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .toast {
    background: #2a2a2a !important;
    border: 1px solid rgba(15, 235, 166, 0.5);
    border-radius: 8px;
    
    .toast-body {
      color: #fff;
      padding: 12px 16px;
      font-size: 0.875rem;
    }
  }
} 