.profile-popup {
  top: 76px;
  border-radius: 32px;
  border: 1px solid var(--Stroke-Card, #0FEBA6);
  background: var(--BG---Green-2, linear-gradient(270deg, rgba(15, 235, 166, 0.00) 0%, rgba(15, 235, 166, 0.20) 100%));
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(12px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  z-index: 999;
  .btn-avatar {
    width: 60px;
    height: 60px;
    padding: 0;
    border: 1px solid var(--Stroke-Card, #0FEBA6);
    justify-content: center;
    img {
      padding: 0;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .avatar-icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    .edit-icon {
      position: absolute;
      top: 72%;
      left: 70%;
      border-radius: 32px;
      background: rgba(255, 255, 255, 0.50);
      backdrop-filter: blur(6px);
      padding: 4px;
    }
  }

  .rounded-full {
    padding: 4px 12px;
    border-radius: 56px;
    border: 1px solid rgba(15, 235, 166, 0.50);
    background: var(--BG---Green-2, linear-gradient(270deg, rgba(15, 235, 166, 0.00) 0%, rgba(15, 235, 166, 0.20) 100%));
    backdrop-filter: blur(6px);
  }
  ul {
    list-style: none;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    text-align: start;
    li {
      cursor: pointer;
      transition: background-color 0.2s ease;
      padding: 8px 16px;
      border-radius: 4px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      img {
        margin-right: 8px;
        max-width: 20px;
        max-height: 20px;
      }
    }
  }
  .btn-sign-out {
    border-radius: 56px;
    border: 1px solid rgba(15, 235, 166, 0.50);
    background: var(--BG---Green-2, linear-gradient(270deg, rgba(15, 235, 166, 0.00) 0%, rgba(15, 235, 166, 0.20) 100%));
    backdrop-filter: blur(6px);
    color: #fff;
    padding: 12px 24px 12px 16px;
    &:hover {
      cursor: pointer;
    }
  }
}