.list-prompt {
  padding: 0 180px;
  .card {
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 16px;
    overflow: hidden;

    &:hover {
      transform: translateY(-4px);
      
      .hover-overlay {
        opacity: 1;
        visibility: visible;
      }
    }

    .card-img-top {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 16px;
    }

    .hover-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(4px);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      border-radius: 16px;

      .use-prompt-btn {
        padding: 12px 24px;
        border-radius: 32px;
        border: 1px solid var(--Stroke-Card, #0FEBA6);
        background: var(--BG---Green-2, linear-gradient(270deg, rgba(15, 235, 166, 0.00) 0%, rgba(15, 235, 166, 0.20) 100%));
        backdrop-filter: blur(10px);
        color: white;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all 0.2s ease;

        &:hover {
          background: linear-gradient(270deg, rgba(15, 235, 166, 0.10) 0%, rgba(15, 235, 166, 0.30) 100%);
          transform: scale(1.05);
        }

        img {
          width: 18px;
          height: 18px;
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}