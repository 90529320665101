.artworks-container {
  padding: 20px 0;
  min-height: 80vh;

  .artwork-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease;
    cursor: pointer;

    &:hover {
      transform: translateY(-5px);

      .artwork-overlay {
        opacity: 1;
      }
    }

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }

    .artwork-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    .artwork-actions {
      display: flex;
      gap: 12px;

      .btn {
        width: 44px;
        height: 44px;
        border-radius: 999px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border: 1px solid #0FEBA6;
        background: linear-gradient(270deg, rgba(15, 235, 166, 0) 0%, rgba(15, 235, 166, 0.2) 100%);
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.1);
          background: linear-gradient(270deg, rgba(15, 235, 166, 0.1) 0%, rgba(15, 235, 166, 0.3) 100%);
          border-color: #0FEBA6;
        }

        &:active {
          transform: scale(0.95);
        }

        &:focus {
          box-shadow: 0 0 0 2px rgba(15, 235, 166, 0.3);
        }

        svg {
          width: 100%;
          height: 100%;
          
          path {
            stroke: #0FEBA6;
            stroke-width: 1.5;
          }
        }

        // Specific styles for delete button
        &:last-child {
          border-color: #FF4D4F;
          background: linear-gradient(270deg, rgba(255, 77, 79, 0) 0%, rgba(255, 77, 79, 0.2) 100%);

          &:hover {
            background: linear-gradient(270deg, rgba(255, 77, 79, 0.1) 0%, rgba(255, 77, 79, 0.3) 100%);
          }

          &:focus {
            box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.3);
          }

          svg path {
            stroke: #FF4D4F;
          }
        }
      }
    }

    .artwork-title {
      margin: 0;
      font-size: 1rem;
      color: #333;
    }

    .text-muted {
      font-size: 0.875rem;
    }
  }
} 