.rc-slider-track {
  background-color: #0FEBA6;
}

.rc-slider-handle {
  background-color: #0FEBA6;
}

.rc-slider-handle:hover {
  border-color: #0FEBA6;
  box-shadow: 0 0 5px #0FEBA6;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #0FEBA6;
  box-shadow: 0 0 0 5px #0FEBA6;
}

.rc-slider-dot-active {
  border-color: #0FEBA6;
}

.range-slider {
  display: flex;
  gap: 32px;
  .rc-slider {
    max-width: 100%;
    margin-top: 10px;
  }
  .range-input {
    display: flex;
    input {
      color: #fff;
      border-radius: 8px;
      border: 1px solid #616161;
      background: #474747;
      max-width: 64px;
      text-align: center;
    }
  }
}