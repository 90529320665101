.generate-popup {
  width: 50%;
  margin: 120px auto 0;
  background: linear-gradient(180deg, #000 0%, #1A1A1A 100%);
  padding: 24px;
  border-radius: 32px;
  border: 1px solid var(--Stroke-Card, #0FEBA6);
  position: relative;
  z-index: 1;

  .popup-header {
    .btn-header {
      padding: 8px 12px;
      
      .icon {
        width: 18px;
        height: 24px;
      }
    }
  }

  .prompt-input {
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.10);
    color: #fff;
    min-height: 150px;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .prompt-input::placeholder {
    color: var(--Gray-10, #F4F4F4);
    opacity: 0.7;
  }

  .popup-footer {
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .options {
      display: flex;
      gap: 8px;

      .option-box-select {
        gap: 10px;
        box-shadow: 0 3px 0 0 #424242;
        &:disabled {
          cursor: not-allowed;
          opacity: 0.7;
        }
      }
    }

    .generate-box {
      display: flex;
      align-items: flex-end;
      gap: 8px;
    }
  }

  .generate-box {
    .btn-enhance {
      border-radius: 44px;
      background: rgba(255, 255, 255, 0.10);
      box-shadow: 0 3px 0 0 #424242;
      .enhance-icon {
        width: 24px;
        height: 24px;
      }
      &:disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }
  }

  .btn-dark {
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    border-radius: 56px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: #1A1A1A;
    backdrop-filter: blur(6px);
  }

  .btn-dark span {
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .btn-dark .social-icon {
    max-width: 24px;
  }

  .btn-dark:hover {
    background: #333;
  }

  .btn-success {
    color: #1A1A1A;
    border-radius: 56px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: #0FEBA6;
    box-shadow: 0 3px 0 0 #077351;
  }

  .btn-generate {
    font-weight: 500;
    min-width: 250px;
    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .option-div {
    position: relative;
    z-index: 1;
    
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: start;
      margin: 0 auto;
    }

    .option-box, .option-box-enhance {
      max-width: 100%;
      padding-bottom: 8px;
      scrollbar-width: thin;
      scrollbar-color: #555 transparent;
      scroll-snap-type: x mandatory;
      display: grid;
      grid-template-rows: repeat(2, auto);
      grid-auto-flow: column;
      overflow-y: hidden;
      white-space: normal;
    }

    .option-item {
      display: flex;
      align-items: center;
      gap: 8px;
      background: #222;
      color: white;
      margin: 12px 16px 0 0;
      border-radius: 999px;
      min-width: 140px;
      white-space: nowrap;
      cursor: pointer;
      transition: 0.3s;
    }

    .option-item:hover {
      background: #333;
    }

    .option-item.option-item-active {
      background: #333;
      border: 1px solid #0FEBA6;
    }

    .option-item span {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 20ch;
    }

    .option-item .icon-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
    }

    .option-item .icon-box .icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .option-item.ratio-item {
      min-width: 300px;
      max-width: none;
    }

    .option-item.ratio-item span {
      width: auto;
      min-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .option-child {
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      background: rgba(255, 255, 255, 0.10);
      padding: 16px;
      margin: 16px 0;

      label {
        display: flex;
        gap: 4px;
        padding-bottom: 16px;
        font-size: 14px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
        align-items: center;
        .icon {
          width: 16px;
          height: 16px;
        }
      }

      textarea {
        min-height: 150px;
        padding: 12px;
        background: rgba(255, 255, 255, 0.10);
        border-radius: 8px;
        color: #fff;
        &::placeholder {
          opacity: 0.7;
        }
      }

      .upload-image {
        label, .image-uploaded {
          border-radius: 8px;
          width: 100px;
          height: 100px;
          margin-top: 16px;
          padding-top: 16px;
          img {
            border-radius: 8px;
          }
          .img-icon-upload {
            border-radius: 0;
          }
        }
        .image-uploaded {
          margin: 0 0 16px 0;
        }
        .border-dash {
          border: 1px dashed rgba(255, 255, 255, 0.20);
          cursor: pointer;
        }
        .icon-red {
          width: 24px;
          height: 24px;
          border-radius: 56px;
          display: flex;
          justify-content: center;
          margin-top: 10px;
          right: -10px !important;
          .icon {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    .option-box-enhance {
      display: flex;
      flex-direction: column;
      .option-item {
        width: 100%;
        background: #333;
        .icon-box {
          background: #454545;
          border-radius: 999px;
          margin: 12px 0 12px 12px;
          .icon {
            width: 24px;
            height: 24px;
            border-radius: 0;
          }
        }
        span {
          width: 10ch;
        }
      }
      .option-child {
        label {
          opacity: 0.8;
          
        }
        .face-option {
          label {
            border-bottom: 0;
            padding-top: 16px;
            width: 100%;
            align-items: center;
          }
        }
        .face-option-advance {
          flex-direction: column;
          margin-bottom: 24px;
        }
        .face-option-advance-label {
          padding-bottom: 0;
        }
        .switch-btn {
          padding-top: 16px;
        }
        .option-header {
          flex-direction: row;
          display: flex;
          text-align: start;
          justify-items: start;
          align-items: start;
  
          .option-title {
            width: 100%;
            .title {
              border-bottom: 0;
              padding-bottom: 0;
            }
            .subtitle {
              opacity: 0.4;
            }
          }
          .switch-btn {
            padding-top: 0;
          }
        }
        .seed-input {
          border-radius: 8px;
          border: 1px solid #616161;
          background: #474747;
          color: #fff;
          
          /* Hide number input spinners */
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          
          /* For Firefox */
          -moz-appearance: textfield;

          &:disabled {
            background: #474747;
            opacity: 0.5;
          }

          &:focus {
            background: #474747;
            border-color: #0FEBA6;
            color: #fff;
            box-shadow: none;
          }

          &::placeholder {
            color: #9E9E9E;
          }
        }
        .custom-checkbox {
          position: relative;
          display: flex;
          align-items: center;

          input[type="checkbox"] {
            width: 24px;
            height: 16px;
            border-radius: 4px;
            border: none;
            appearance: none;
            background: #474747;
            border: 1px solid #616161;
            cursor: pointer;
            position: relative;
            z-index: 1;

            &:checked {
              background: #0FEBA6;
              border-color: #0FEBA6;
            }
          }

          .checkbox-icon {
            position: absolute;
            left: 2px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            display: none;
            z-index: 2;
            width: 16px;
            height: 16px;
          }

          input[type="checkbox"]:checked ~ .checkbox-icon {
            display: block;
          }

          label {
            margin-left: 8px;
            margin-bottom: 0;
            cursor: pointer;
            border-bottom: 0;
            padding-bottom: 0;
            padding-top: 0;
          }
        }
      }
    }
  }
  }


.result-section {
  border-radius: 8px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  h4 {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 20px;
    justify-content: start;
    display: flex;
  }

  .generated-images-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin-bottom: 20px;
  }

  .generated-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    aspect-ratio: 1;
    overflow: hidden;
    
    &.loading {
      background: linear-gradient(90deg, #1a1a1a 25%, #333 50%, #1a1a1a 75%);
      background-size: 200% 100%;
      animation: loading 1.5s infinite;
      
      &::after {
        content: "Loading...";
        position: absolute;
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    &.watermarked {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1;
        background: rgba(0, 0, 0, 0.50);
        backdrop-filter: blur(6px);
      }

      .pro-badge {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        border-radius: 20px;
        z-index: 2;
        border-radius: 32px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 126.25%);

        .pro-icon {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }

        .pro-text {
          color: white;
          font-size: 1.2rem;
          font-weight: bold;
        }
      }

      .unlock-text {
        position: absolute;
        bottom: 25%;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        font-size: 0.9rem;
        text-align: center;
        width: 100%;
        z-index: 2;
      }
    }
  }

  p {
    color: #fff;
    margin-bottom: 8px;
    
    strong {
      color: #aaa;
      margin-right: 8px;
    }
  }
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

// Media Queries
@media (max-width: 1440px) {
  .generate-popup {
    width: 80%;
    .popup-footer {
      gap: 12px;
      align-items: center;
    }
    .btn-generate {
      min-width: 60%;
    }
    .options {
      justify-content: start;
      width: 100%;
      .option-box-select {
        gap: 2px;
      }
    }
  }

  .generate-box {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .option-item.ratio-item {
    min-width: 250px;
  }
  
  .option-item.ratio-item span {
    min-width: 150px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .generate-popup {
    width: 70%;
  }
}

@media (min-width: 851px) and (max-width: 992px) {
  .generate-popup {
    width: 80%;
    padding: 16px;
  }

  .option-item.ratio-item {
    min-width: 200px;
  }
  
  .option-item.ratio-item span {
    min-width: 120px;
  }
}

@media (min-width: 76px) and (max-width: 850px) {
  .popup-footer {
    flex-direction: column;
    gap: 12px;
    align-items: center;
    .options {
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
    .generate-box {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .btn-generate {
      min-width: 50%;
    }
  }
}

@media (min-width: 575px) and (max-width: 576px) {
  .generate-popup {
    width: 90%;
    padding: 12px;
    .popup-footer {
      flex-direction: column;
      gap: 12px;
    }
    .options {
      flex-wrap: wrap;
      justify-content: center;
    }
    .btn-generate {
      min-width: 200px;
    }
  }
}

@media (max-width: 576px) {
  .generate-popup {
    width: 95%;
    padding: 10px;
    .btn-header {
      font-size: 14px;
      padding: 6px 10px;
    }
    .btn-generate {
      min-width: 180px;
    }

    .option-item.ratio-item {
      min-width: 180px;
    }
    
    .option-item.ratio-item span {
      min-width: 100px;
    }
  }
}

@media (max-width: 768px) {
  .result-section {
    .generated-images-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 576px) {
  .result-section {
    .generated-images-grid {
      grid-template-columns: 1fr;
    }
  }
}

.option-box::-webkit-scrollbar {
  height: 6px;
}

.option-box::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

.option-box-enhance::-webkit-scrollbar {
  height: 6px;
}

.option-box-enhance::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

.btn {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.btn-header {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

@media (max-width: 1200px) {
  .result-section {
    .generated-images-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: 768px) {
  .result-section {
    .generated-images-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 576px) {
  .result-section {
    .generated-images-grid {
      grid-template-columns: 1fr;
    }
  }
}

