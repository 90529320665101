.footer {
  color: white;
  text-align: center;
  padding: 0 10%;
  margin-top: 100px;

  .logo {
    display: flex;
    margin: 0 auto;
    max-width: 200px;
    min-height: 48px;
    align-items: center;
    gap: 13px;
    padding: 0 24px;
  }
  .footer-logo {
    max-width: 200px;
  }
  .footer-description {
    text-align: center;
    padding: 0 25%;
  }

  .footer-copyright {
    opacity: 0.5;
    margin-top: 16px;
  }

  .social-btn {
    padding-bottom: 155px;
    .footer-buttons {
      img {
        max-width: 50px;
      }
    }
  }

  .footer-menu {
    ul {
      text-decoration: none;
      list-style: none;
      justify-content: center;
      margin: 0 auto;
      gap: 40px;
      li {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
