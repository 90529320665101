/* Main Container */
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Content Wrapper */
.content-wrapper {
  flex: 1;
}
